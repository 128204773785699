import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//api
import { useGetResultsMutation, useReadAndSaveFileMutation } from 'src/redux/queries';
import { useLazyGetdocumentTypesQuery } from 'src/redux/queries/documentTypes';
import { useLazyGetPromptQuery } from 'src/redux/queries/prompts';
import { useLazyGetAllPersonasQuery } from 'src/redux/queries/Personas';
import {
    useAddCampaignMutation,
    useLazyGetCampaignQuery,
    useUpdateCampaignMutation
} from 'src/redux/queries/Campaigns';
import { useLazyGetAudinecesQuery } from 'src/redux/queries/Audience';
import { useLazyGetLindekdinDataQuery } from 'src/redux/queries/Linkedin';
import { useGenerateEmbeddingsMutation } from 'src/redux/queries/Embeddings';
import { useAddContentMutation, useUpdateContentMutation } from 'src/redux/queries/Content';

//helpers
import { startLoading, stopLoading } from 'src/redux/reducers';
import { validateData } from 'src/views/Private/AdvancedCampaign/helpers';
import {
    deepCopy,
    generateSummaryPrompt,
    joinSummary,
    summarizePdf
} from 'src/shared/utils/helpers';
import { toast } from 'react-toastify';
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';
import useCustomNavigation from 'src/hooks/useCustomNavigations';
import { useLocation, useParams } from 'react-router-dom';
import {
    generateBdrCadenceData,
    generateBdrEmailData,
    generateCampaignData,
    generatePersonalizedData
} from './widgets/ContentForm/helpers';
import { debounce, formatSubType } from 'src/shared/utils';

//hooks
import useGenerateTags from 'src/hooks/useGenerateTags';

//constants
import { ERRORS, STRINGS } from 'src/shared/strings';
import {
    AUDIENCE,
    CLAP_SESSION,
    CONTENT_FILE_TYPES,
    CONTENT_TYPE,
    DESIRED_INPUT_ORDER,
    MaxWordLimit,
    PDF_SPLIT_LIMIT,
    ROUTES
} from 'src/shared/constants';

//widgets
import { CustomButton } from 'src/widgets';
import { ContentForm, PreviewDoc, ResultTab } from 'src/views/Private/AdvancedCampaign/widgets';

//styles
import styles from './styles.module.scss';

const defaultFormData = {
    file: [{ files: [], value: '', type: CONTENT_FILE_TYPES.PDF.value }],
    contentType: [],
    persona: '',
    toneRegister: '',
    audience: [AUDIENCE[3]] || [],
    mType: '',
    title: '',
    source: '',
    goal: '',
    theme: '',
    demography: '',
    relevantProducts: '',
    seoKeywords: '',
    feedback: [],
    contacts: [],
    groupBy: ''
};

const AdvancedCampaign = () => {
    const handleNavigation = useCustomNavigation();
    const dispatch = useDispatch();
    const config = useSelector((state) => state.config);
    const userOrgs = useSelector((state) => state.user.userDetails?.orgs) || [];
    const defaultOrg = userOrgs.find((uo) => uo?.isDefault) || { name: '', code: '' };
    const { id } = useParams();
    const loc = useLocation();

    const [timeline, setTimeline] = useState([0]);
    const [formData, setFormData] = useState({
        ...defaultFormData,
        orgCode: defaultOrg?.code || userOrgs[0]?.code || '',
        orgName: defaultOrg?.name || userOrgs[0]?.name || ''
    });
    const [isLoading, setIsLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [pageView, setPageView] = useState(0);
    const [choices, setChoices] = useState([]);
    const [pdfText, setPdfText] = useState('');
    const [maxWords, setMaxWords] = useState(MaxWordLimit);
    const [regenerateCount, setRegenerateCount] = useState(0);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [campaignData, setCampaignData] = useState(null);
    const [regeneratedChoices, setRegeneratedChoices] = useState([]);
    const [personas, setPersonas] = useState([]);
    const [audiences, setAudiences] = useState(AUDIENCE);
    const [campaignId, setCampaignId] = useState(null);
    const [tags, setTags] = useState('');
    const [savedAudiences, setSavedAudiences] = useState([]);
    const [savedPersonas, setSavedPersonas] = useState([]);

    const handleFormUpdate = (data) => {
        setFormData((state) => ({ ...state, ...data }));
    };

    const [getResults] = useGetResultsMutation();
    const [saveAndReadFile] = useReadAndSaveFileMutation();
    const [getDocumentTypes] = useLazyGetdocumentTypesQuery();
    const [getPersonas] = useLazyGetAllPersonasQuery();
    const [getCampaign] = useLazyGetCampaignQuery();
    const [addCampaign] = useAddCampaignMutation();
    const [updateCampaign] = useUpdateCampaignMutation();
    const [getPrompts] = useLazyGetPromptQuery();
    const generateTags = useGenerateTags();
    const [getAudiences] = useLazyGetAudinecesQuery();
    const [getLinkedinData] = useLazyGetLindekdinDataQuery();
    const [addContent] = useAddContentMutation();
    const [updateContent] = useUpdateContentMutation();
    const [generateEmbeddings] = useGenerateEmbeddingsMutation();

    const FILE_LIMIT = config.fileChunkSize || PDF_SPLIT_LIMIT;

    useEffect(() => {
        // optimize this afterwards
        if (!id) init();

        fetchData();

        return () => {
            sessionStorage.removeItem('summary');
            sessionStorage.removeItem(CLAP_SESSION);
            init();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, loc]);

    useEffect(() => {
        if (campaignData) {
            sessionStorage.removeItem('summary');
            let data = deepCopy(campaignData);
            if (data?.content[0]?.choices[0]?.choices) {
                setChoices(data.content || []);
            } else {
                setChoices([{ choices: [...data.content] }] || []);
            }

            let persona = personas.find((p) => p._id === data.filters?.persona);
            const filters = { ...data.filters };
            setFormData({
                ...formData,
                ...filters,
                persona,
                audience:
                    filters.audience instanceof Array ? [...filters.audience] : [filters.audience],
                file: data.filters?.asset,
                title: data.title,
                source: data.source,
                subType: formatSubType(data.filters?.subType),
                orgCode: data.orgId,
                orgName: data.orgName,
                feedback: data.feedback || [],
                type: data.subType,
                campaignType: data.campaignType,
                contacts: data.content?.map((content) => content?.audience)
            });
            setPdfText(data.source);
            if (filters?.audience && typeof filters?.audience !== 'string') {
                const audience = filters?.audience;
                let ads = [];
                if (audience?.title || audience?.role) {
                    audience.tooltip = `
                    Segment: ${audience.segment || ''}
                    Industry: ${audience.targetIndustry || audience.industry}
                    Company: ${audience.companyProfile || audience.companyName}
                    KPIs: ${audience.kpis || audience.personalityIndicators}
                    Linkedin: ${audience.linkedinProfile || audience.linkedinUrl}`;

                    ads = [...audiences, audience];
                } else {
                    ads = [...audiences];
                }

                ads = ads.reduce((acc, obj) => {
                    const hasDuplicate = acc.some((item) => item._id && item._id === obj._id);
                    if (!hasDuplicate) {
                        acc.push(obj);
                    }
                    return acc;
                }, []);

                setAudiences([...ads]);
            }
            if (campaignData?.tags) {
                setTags(campaignData?.tags);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignData]);

    useEffect(() => {
        const screenName =
            pageView === 0
                ? ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION
                : pageView === 1
                ? ANALYTICS.SCREEN_NAMES.RESULTS_VIEW
                : ANALYTICS.SCREEN_NAMES.DOC_CREATER_VIEW;

        analytics.sendEvent(screenName);
        if (pageView === 0) {
            setMaxWords({ ...MaxWordLimit });
        }

        if (pageView === 2) {
            window.scrollTo({
                top: 0
                // behavior: 'smooth'
            });
        }
    }, [pageView]);

    useEffect(() => {
        generateAudiences(savedAudiences);
        generatePersonas(savedPersonas);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.orgCode]);

    const init = () => {
        setTimeline([0]);
        setFormData({
            ...defaultFormData,
            orgCode: defaultOrg?.code || userOrgs[0]?.code || '',
            orgName: defaultOrg?.name || userOrgs[0]?.name || ''
        });
        setErrors({});
        setPageView(0);
        setChoices([]);
        setPdfText('');
        setMaxWords(MaxWordLimit);
        setRegenerateCount(0);
        setDocumentTypes([]);
        setCampaignData(null);
        setRegeneratedChoices([]);
        setPersonas([]);
        setCampaignId(null);
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            dispatch(startLoading());
            fetchDocumentTypes();
            await fetchAudiences();
            await fetchPersonas();
            if (loc?.state?.new) {
                return;
            } else {
                await getCampaignData();
            }
        } catch (e) {
            console.log('error >>>', e);
            if (e.status === 404) {
                toast.error(ERRORS.CAMPAIGN_NOT_FOUND, { toastId: 'fetchCampagin' });
                handleNavigation(ROUTES.HOME);
            }
        } finally {
            dispatch(stopLoading());
            setIsLoading(false);
        }
    };

    const fetchDocumentTypes = async () => {
        await getDocumentTypes()
            .unwrap()
            .then((data) => {
                setDocumentTypes(data?.data || []);
            });
    };

    const getCampaignData = async () => {
        if (!id) return;
        setPageView(1);
        await getCampaign({ id })
            .unwrap()
            .then((data) => {
                setCampaignData(data?.data || []);
            });
    };

    const fetchPersonas = async () => {
        await getPersonas()
            .unwrap()
            .then((data) => {
                let savedPersonas = [...data?.data] || [];
                setSavedPersonas(savedPersonas);
                generatePersonas(savedPersonas);
            });
    };

    const generatePersonas = useCallback(
        (savedPersonas) => {
            let personas = savedPersonas.filter(
                (persona) => !persona.orgId || persona.orgId === formData.orgCode
            );
            personas?.sort((a, b) => a.name.localeCompare(b.name));
            let defaultPersonaPos = personas?.findIndex((persona) => persona.default);
            let defaultPersona = {};
            if (defaultPersonaPos !== -1 && !formData?.persona) {
                defaultPersona = personas?.splice(defaultPersonaPos, 1)[0];
                setPersonas([defaultPersona, ...personas] || []);
                setFormData((state) => ({
                    ...state,
                    persona: defaultPersona,
                    toneRegister: defaultPersona?.tone
                }));
            } else {
                setPersonas([...personas] || []);
                if (!personas.find((persona) => persona._id === formData?.persona?._id)) {
                    setFormData((state) => ({ ...state, persona: '', toneRegister: '' }));
                }
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formData.orgCode]
    );

    const fetchAudiences = async () => {
        await getAudiences()
            .unwrap()
            .then((data) => {
                if (data?.data?.length) {
                    let savedAudiences = [...data?.data] || [];
                    setSavedAudiences(savedAudiences);
                    generateAudiences(savedAudiences);
                }
            });
    };

    const generateAudiences = useCallback(
        (savedAudiences) => {
            let audiences = savedAudiences.filter(
                (audinece) => !audinece.ownerOrgId || audinece.ownerOrgId === formData.orgCode
            );
            audiences = audiences?.map((audience) => {
                if (audience?.type === 'personalized') return audience;

                return {
                    ...audience,
                    label: audience?.title,
                    value: audience?.title,
                    tooltip: ` 
                <b>Segment</b>: ${audience.segment || '--'} |
                <b>Industry</b>: ${audience.targetIndustry || audience.industry} |
                <b>Company</b>: ${audience.companyProfile || audience.companyName}`
                };
            });
            setAudiences([...AUDIENCE, ...audiences]);

            const as = [...AUDIENCE, ...audiences];
            const sa = Array.isArray(formData.audience) ? formData.audience : []; // Ensure sa is an array
            if (sa.length === 0 || !sa.some((audi) => as.some((x) => x.value === audi.value))) {
                setFormData((state) => ({ ...state, audience: [] }));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formData.orgCode]
    );

    const addCampaignData = async (choices = []) => {
        try {
            if (loc.state.new && choices?.length && !campaignId) {
                let { file, title, source, persona, fileLinks, campaignType, groupBy, ...rest } =
                    formData;
                if (fileLinks)
                    file = file.map((f) => {
                        return {
                            ...f,
                            files: fileLinks[f.type]
                        };
                    });

                let tagData = {
                    title,
                    content: choices,
                    persona,
                    ...rest
                };
                const tags = ['']; //await generateTags(tagData);
                setTags(tags);

                const payload = {
                    title: title,
                    content: choices,
                    filters: {
                        ...rest,
                        contacts: rest.contacts?.map((contact) => contact),
                        persona: persona._id,
                        asset: file,
                        subType: rest?.subType?.value,
                        groupBy: rest.groupBy
                    },
                    source: source,
                    status: 'draft',
                    orgCode: formData.orgCode || userOrgs.find((org) => org.isDefault)?.code || '',
                    orgName: formData?.orgName || userOrgs.find((org) => org.isDefault)?.name || '',
                    tags: tags?.join(', '),
                    campaignType
                };

                let campaign = await addCampaign({ ...payload });
                await addContent({
                    ...payload,
                    campaignId: campaign?.data?.data?._id
                })
                    .unwrap()
                    .then((data) => {
                        let Embeddings = generateEmbeddings({
                            objectId: data?._id,
                            orgId: formData?.orgCode
                        });
                        console.log('Embeddings >>>> ', Embeddings);
                    });

                setCampaignId(campaign?.data?.data?._id);
            } else {
                updateCampaignData(choices);
            }
        } catch (err) {
            toast.error(err?.data?.message);
        }
    };

    const updateCampaignData = async (choices = []) => {
        try {
            if (!loc.state.new && choices?.length) {
                let { file, title, source, persona, fileLinks, campaignType, groupBy, ...rest } =
                    formData;
                if (fileLinks)
                    file = file.map((f, i) => {
                        return {
                            ...f,
                            files: fileLinks[f.type]
                        };
                    });

                const payload = {
                    id,
                    data: {
                        title: title,
                        content: choices,
                        filters: {
                            ...rest,
                            contacts: rest.contacts?.map((contact) => contact),
                            persona: persona._id,
                            asset: file,
                            subType: rest?.subType?.value,
                            groupBy
                        },
                        source: source,
                        status: 'draft',
                        orgCode: formData?.orgCode,
                        orgName: formData?.orgName,
                        campaignType
                    }
                };
                await updateCampaign({ ...payload });
                await updateContent({ ...payload })
                    .unwrap()
                    .then((data) => {
                        generateEmbeddings({
                            objectId: data?.data?._id,
                            orgId: formData?.orgCode
                        });
                    })
                    .catch((err) => console.log(err));
            }
        } catch (err) {
            toast.error(err?.data?.message);
        }
    };

    const handleUpdateTimeline = async (value) => {
        if (!value) {
            setTimeline([0]);
            return;
        }
        timeline.push(value);
        setTimeline([...timeline]);
    };

    const handleNext = async () => {
        try {
            dispatch(startLoading());

            if (pageView === 0) {
                analytics.sendClickEvent(
                    'go_to_result_tab_click',
                    ANALYTICS.SCREEN_NAMES.CONTENT_SELECTION,
                    ANALYTICS.ACTIONS.NEXT_CLICK
                );
            }

            if (pageView === 2) {
                analytics.sendClickEvent(
                    ANALYTICS.SCREEN_NAMES.DOC_CREATER_VIEW,
                    ANALYTICS.ACTIONS.START_AGAIN
                );
                resetState();
                return;
            }

            if (pageView === 1) {
                analytics.sendClickEvent(
                    'go_to_download_preview_tab_click',
                    ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                    ANALYTICS.ACTIONS.NEXT_CLICK
                );
                addCampaignData([...choices]);
                setPageView((state) => state + 1);
                let updatedTimeline = pageView + 1;
                handleUpdateTimeline(updatedTimeline);
                return;
            }

            const errors = validateData(formData);
            setErrors({ ...validateData(formData) });
            if (errors && Object.values(errors).length) {
                return;
            }

            let results = await getPromptResults();
            setChoices([...results]);
            setPageView((state) => state + 1);
            addCampaignData([...results]);
            let updatedTimeline = pageView + 1;
            handleUpdateTimeline(updatedTimeline);
        } catch (e) {
            console.log(e);
            toast.error(e, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } finally {
            dispatch(stopLoading());
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSaveData = useCallback(
        debounce(async (data) => {
            try {
                if (!data?.length || !formData?.title) return;
                await addCampaignData([...data]);
            } catch (e) {
                console.log(e);
                toast.error(e, {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
        }, 1500),
        [formData]
    );

    const handleSave = async () => {
        debouncedSaveData(choices);
    };

    const getPromptResults = async () => {
        const summary = sessionStorage.getItem('summary');
        if (summary) {
            let results = await promptCalls(formData.audience, formData.contentType, summary);
            return results;
        }

        let text = '';
        let key = new Date().getTime();
        sessionStorage.setItem(CLAP_SESSION, `${key}`);
        let { file, toneRegister, audience, contentType, mType, title } = formData;
        // Create a new array with objects rearranged based on the desired order
        const FILES = DESIRED_INPUT_ORDER.map((type) => file.find((f) => f.type === type)).filter(
            Boolean
        );
        let data = [];
        let fileUrls = {};
        for (const f of FILES) {
            if (f.files?.length || f.value) {
                const payload = {
                    ...f,
                    fileSession: key,
                    mType: 1,
                    title,
                    tone: toneRegister,
                    audience,
                    contentType
                };
                let x = await saveAndReadFile(payload).unwrap();
                data.push(x?.data?.text);
                if (x?.data?.fileLinks) {
                    fileUrls[f.type] = x?.data?.fileLinks[f.type];
                }
            }
        }

        text = data.join(' ');
        formData.source = text;
        formData.fileLinks = fileUrls;
        setFormData(formData);

        if (text?.length >= FILE_LIMIT) {
            let tokenCount = 0;
            let splitContent = await summarizePdf(text, FILE_LIMIT);
            let proccessedData = splitContent.map(async (content) => {
                let message = generateSummaryPrompt(content, config?.summaryLength);
                let response = [];
                await getResults({ message })
                    .unwrap()
                    .then((res) => {
                        response = { choices: res?.choices };
                        tokenCount = tokenCount + res?.total_tokens;
                    })
                    .catch((error) => {
                        throw error?.data?.message;
                    });
                return response;
            });
            let data = await Promise.all([...proccessedData]);
            let combinedSummary = joinSummary(data.flat());
            setPdfText(combinedSummary);
            sessionStorage.setItem('summary', combinedSummary);
            let results = await promptCalls(
                formData.audience,
                formData.contentType,
                combinedSummary,
                0,
                tokenCount
            );
            return results;
        }
        sessionStorage.setItem('summary', text);
        let summaryInfo = text ?? '';
        setPdfText(summaryInfo);

        let results = await promptCalls(formData.audience, formData.contentType, summaryInfo);
        return results;
    };

    const promptCalls = async (
        audience,
        contentType,
        summaryInfo,
        regCount,
        tokenCount,
        key,
        resultInstructions = '',
        isRegenerate
    ) => {
        let { persona, toneRegister, mType, instructions, seoKeywords } = formData;

        if (audience?.linkedinUrl) {
            let linkedinData = await getLinkedinData({
                profile_url: audience?.linkedinUrl
            }).unwrap();
            audience.linkedinData = JSON.stringify(linkedinData?.data);
        }

        let targetAudineceResult = [];

        if (formData.campaignType === 'personalized') {
            targetAudineceResult = audience.map(async (target) => {
                let prompt = [
                    await generatePersonalizedData({
                        key,
                        documentTypes,
                        summaryInfo,
                        persona,
                        audience: target,
                        toneRegister,
                        content: 'bdrCadence',
                        maxWords,
                        subType: formData?.subType?.value,
                        regCount,
                        tokenCount,
                        mType: mType === 0 ? 0 : 1,
                        getResults
                    })
                ];

                let choices = await Promise.all([...prompt]);
                if (choices && choices.length) {
                    choices = choices.flat();
                    return { choices, audience: target };
                }

                return [];
            });
        } else {
            let targets = isRegenerate ? audience : formData.contacts;
            targetAudineceResult = targets.map(async (target, idx) => {
                let prompt = contentType.map(async (content) => {
                    switch (content) {
                        case 'advanced-bdrCadence':
                        case 'bdrCadence':
                            let bdrCadenceData = await generateBdrCadenceData({
                                key,
                                documentTypes,
                                summaryInfo,
                                persona,
                                audience: audience[idx] || audience[0],
                                toneRegister,
                                content,
                                maxWords,
                                subType: formData?.subType?.value,
                                regCount,
                                tokenCount,
                                mType: 1,
                                emotionalFilter: formData.emotionalFilter,
                                contentFilter: formData.contentFilter,
                                personaFilter: formData.personaFilter,
                                contact: target,
                                instructions: `${instructions || ''} ${resultInstructions}`,
                                getResults
                            });
                            return bdrCadenceData;

                        case 'bdrEmail':
                            let bdrEmailData = await generateBdrEmailData({
                                key,
                                documentTypes,
                                summaryInfo,
                                persona,
                                audience: target,
                                toneRegister,
                                content,
                                maxWords,
                                regCount,
                                tokenCount,
                                mType: mType === 0 ? 0 : 1,
                                instructions: `${instructions || ''} ${resultInstructions}`,
                                getResults
                            });
                            return bdrEmailData;

                        default:
                            let campaignData = await generateCampaignData({
                                documentTypes,
                                summaryInfo,
                                persona,
                                content,
                                audience: target,
                                maxWords,
                                tokenCount,
                                regCount,
                                mType,
                                toneRegister,
                                instructions: `${instructions || ''} ${resultInstructions}`,
                                seoKeywords: seoKeywords,
                                getResults,
                                isRegenerate
                            });
                            return campaignData;
                    }
                });

                let choices = await Promise.all([...prompt]);
                if (choices && choices.length) {
                    choices = choices.flat();
                    return { choices, audience: target };
                }

                return [];
            });
        }

        let res = await Promise.all([...targetAudineceResult]);
        return res || [];
    };

    const handleRegenerate = async (audience, contentType = [], targetKey, key, instructions) => {
        let count = regenerateCount + 1;
        setRegenerateCount(count);
        try {
            dispatch(startLoading());

            let results = await promptCalls(
                audience,
                contentType,
                pdfText,
                count,
                0,
                key,
                instructions,
                true
            );

            results.forEach((content) => {
                const isMultiple = [CONTENT_TYPE[1].value].includes(content.choices[0].type);
                // if ([CONTENT_TYPE[2].value, 'bdrCadence'].includes(content.choices[0].type)) {
                let pos = choices[targetKey]?.choices?.findIndex(
                    (choice) => choice.type === content.choices[0].type
                );

                if (pos !== -1) {
                    if (!regeneratedChoices[targetKey]) {
                        regeneratedChoices[targetKey] = { contentTypes: [] };
                    }

                    if (!regeneratedChoices[targetKey].contentTypes[pos]) {
                        regeneratedChoices[targetKey].contentTypes[pos] = { choices: [] };
                    }

                    if (isMultiple) {
                        regeneratedChoices[targetKey].contentTypes[pos].choices =
                            content?.choices[0]?.choices;
                    } else {
                        regeneratedChoices[targetKey].contentTypes[pos].choices[key] =
                            content?.choices[0]?.choices[key];
                    }
                }
            });
            setChoices([...choices]);
            setRegeneratedChoices([...regeneratedChoices]);
        } catch (e) {
            console.log('error >>> ', e);
            toast.error(e, {
                position: toast.POSITION.BOTTOM_CENTER
            });
        } finally {
            dispatch(stopLoading());
        }
    };

    const handleBack = () => {
        if (campaignData?.status === 'complete') {
            analytics.sendClickEvent(
                'complete_campaign_back_click',
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.BACK_CLICK
            );

            return;
        }
        if (pageView === 2) {
            analytics.sendClickEvent(
                'back_to_result_tab_click',
                ANALYTICS.SCREEN_NAMES.DOC_CREATER_VIEW,
                ANALYTICS.ACTIONS.BACK_CLICK
            );
        } else if (pageView === 1) {
            setRegeneratedChoices([]);
            analytics.sendClickEvent(
                'back_to_content_tab_click',
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.BACK_CLICK
            );
            setRegenerateCount(0);
        }
        setPageView((state) => state - 1);
        timeline.splice(pageView, 1);
        setTimeline([...timeline]);
    };

    const resetState = () => {
        init();
    };

    const handleRegenerateChoices = async (audience, type, targetKey, key, instructions) => {
        let regenerate = [];
        regenerate.push(type);

        let targetAudience = [];
        targetAudience.push(audience);
        await handleRegenerate(targetAudience, regenerate, targetKey, key, instructions);
    };

    const handleAcceptedChoices = (targetKey, key, type) => {
        let pos = choices[targetKey]?.choices?.findIndex((choice) => choice?.type === type);
        if (pos !== -1 && choices[targetKey].choices[pos].accepted === true) {
            choices[targetKey].choices[pos].accepted = false;
        } else {
            choices[targetKey].choices[pos].accepted = true;
        }
        setChoices([...choices]);
    };

    const handleFeedback = async (type, audienceLabel, pos, isLiked, comment) => {
        const feedbackData = {
            isLiked,
            comment,
            contentType: type,
            position: pos,
            subType: formData?.subType?.label,
            audienceType: audienceLabel
        };

        let feedback = formData.feedback || [];
        let feedbackPos = feedback.findIndex(
            (feed) =>
                feed.audienceType === audienceLabel &&
                feed.contentType === type &&
                feed.position === pos
        );
        if (feedbackPos !== -1) {
            feedback[feedbackPos] = feedbackData;
        } else {
            feedback.push(feedbackData);
        }

        setFormData({ ...formData, feedback });
        await updateCampaign({
            id,
            data: {
                feedback
            }
        });
    };

    const renderPageView = () => {
        switch (pageView) {
            case 1:
                return (
                    <ResultTab
                        data={choices}
                        updateChoices={setChoices}
                        regenerateResults={handleRegenerateChoices}
                        acceptResults={handleAcceptedChoices}
                        maxWords={maxWords}
                        handleMaxWords={setMaxWords}
                        regeneratedChoices={regeneratedChoices}
                        updateRegeneratedChoices={setRegeneratedChoices}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        title={formData.title}
                        isComplete={campaignData?.status === 'complete'}
                        tags={tags}
                        subType={formData?.subType?.label}
                        feedback={formData?.feedback}
                        handleFeedback={handleFeedback}
                        content={pdfText}
                        seoKeywords={formData.seoKeywords}
                        handleSave={handleSave}
                        groupBy={formData.groupBy}
                    />
                );
            case 2:
                return (
                    <PreviewDoc
                        data={choices}
                        reset={resetState}
                        title={formData.title}
                        campaignId={campaignId || campaignData?._id}
                        isComplete={campaignData?.status === 'complete'}
                    />
                );
            default:
                return (
                    <ContentForm
                        data={formData}
                        updateData={handleFormUpdate}
                        errors={errors}
                        handleError={setErrors}
                        personas={personas}
                        audiences={audiences}
                        handleSubmit={handleNext}
                        onAddPersonaSuccess={setPersonas}
                        onAddAudienceSuccess={setAudiences}
                    />
                );
        }
    };

    return (
        <div className={styles.container}>
            {!isLoading && (
                <>
                    {renderPageView()}
                    {pageView === 2 && campaignData?.status !== 'complete' && (
                        <div className={styles.containerFooter}>
                            <div className={styles.containerButtonLeft}>
                                <CustomButton title={STRINGS.BACK} onClick={handleBack} />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default React.memo(AdvancedCampaign);
