import React, { useEffect, useState } from 'react';
import cx from 'classnames';

//helpers
import { toast } from 'react-toastify';

//widgets
import { CustomButton, CustomInput } from 'src/widgets';
import BdrMail from './Widgets/BdrMail';
import BdrCadenceResult from './Widgets/BdrCadenceResult';
import Feedback from './Widgets/Feedback';
import BetaTag from 'src/components/BetaTag';
import CustomActions from './Widgets/CustomActions';

//styles
import styles from './styles.module.scss';

//constants
import { LABELS, STRINGS } from 'src/shared/strings';
import { MaxWordLimit, CONTENT_TYPE } from 'src/shared/constants';
import { ICON_CANCEL, ICON_COMMAND } from 'src/shared/constants/icons';

//assets
import { ListData } from './Widgets';

//analytics
import { ANALYTICS, analytics } from 'src/shared/utils/Analytics';

//defs
import { IAudience, IChoices, IFeedback, IMaxWordLimit, ITargetChoice } from 'src/defs';

//helpers
import { deepClone } from 'src/shared/utils';

export interface IRegeneratedChoice {
    choices: string[];
}

interface IProps {
    data: [];
    regeneratedChoices: { contentTypes: IRegeneratedChoice[] }[];
    title: string;
    isComplete: boolean;
    maxWords: IMaxWordLimit;
    tags: string;
    subType: string;
    feedback: IFeedback[];
    content: string;
    seoKeywords?: string;
    regenerateResults: (
        audience: IAudience,
        type: string,
        targetKey: number,
        key: number,
        Instructions?: string
    ) => void;
    handleMaxWords: (maxWords: IMaxWordLimit) => void;
    updateChoices: (data: IChoices[]) => void;
    updateRegeneratedChoices: (data: { contentTypes: IRegeneratedChoice[] }[]) => void;
    handleNext: () => void;
    handleBack: () => void;
    acceptResults: (targetKey: number, key: number, type: String) => void;
    handleFeedback: (
        type: string,
        audienceLabel: string,
        key: number | undefined,
        isLike: boolean,
        comment: string
    ) => void;
    handleSave: VoidFunction;
}

const actionsCss = cx('flex-1', 'mt-4 mx-1', 'bg-gray-300 text-black', 'hover:bg-gray-400');

const ResultTab = ({
    data = [],
    regenerateResults,
    acceptResults,
    handleMaxWords,
    maxWords = MaxWordLimit,
    updateChoices,
    regeneratedChoices = [],
    updateRegeneratedChoices,
    handleNext,
    handleBack,
    title = '',
    isComplete = false,
    tags = '',
    subType = '',
    feedback = [],
    handleFeedback,
    seoKeywords = '',
    content = '',
    handleSave
}: IProps) => {
    const [updatedData, setUpdatedData] = useState<IChoices[]>([]);
    const [showInstructionBox, setShowInstructionBox] = useState<number | null>(null);
    const [resultInstructions, setResultInstructions] = useState<string>('');
    const [currentTrends, setCurrentTrends] = useState<string>('');
    const [isEditable, setIsEditable] = useState<boolean>(true);

    useEffect(() => {
        document.title = 'Campaign Like a Pro - Approve Campaign Output';

        setUpdatedData([...data.map((d) => d)]);
    }, [data]);

    // const handleAccept = (targetKey: number, key: number, type: String) => {
    //     acceptResults(targetKey, key, type);
    // };

    const handleRegenerate = (
        audience: IAudience,
        targetKey: number,
        key: number,
        type: string,
        resultInstructions?: string
    ) => {
        analytics.sendClickEvent(
            'regenrate_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.REGENERATE_CLICK
        );
        regenerateResults(audience, type, targetKey, key, resultInstructions);
    };

    const handleRangeChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        analytics.sendClickEvent(
            'word_range_change_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.WORD_COUNT_CHANGE
        );
        handleMaxWords({ ...maxWords, [type]: Number(e.target.value) });
    };

    const updateResult = (
        value: string | string[],
        targetKey: number,
        key: number,
        idx: number,
        type: string,
        saveData = false,
        isMerge = false
    ) => {
        try {
            let choices = [...updatedData[targetKey].choices[key]?.choices];
            if (typeof value === 'string') {
                choices[idx] = value;
            } else {
                choices = value;
            }
            updatedData[targetKey].choices.splice(key, 1, {
                choices,
                accepted: updatedData[targetKey].choices[key]?.accepted,
                type
            });
            setUpdatedData([...updatedData]);
            if (saveData) {
                updateChoices([...updatedData]);
                if (typeof value === 'string') {
                    regeneratedChoices[targetKey]?.contentTypes[key]?.choices?.splice(idx, 1, '');
                } else {
                    if (regeneratedChoices?.length)
                        regeneratedChoices[targetKey].contentTypes[key].choices = [];
                }
                updateRegeneratedChoices([...regeneratedChoices]);
            }
            if (isMerge) setCurrentTrends('');
            handleSave();
        } catch (e) {
            console.log('error>>', e);
        }
    };

    const updateRegenChoices = (
        value: string,
        targetKey: number,
        key: number,
        idx: number
    ) => {
        try {
            let regenChoices = deepClone(regeneratedChoices);
            regenChoices[targetKey].contentTypes[key].choices[idx] = value;
            updateRegeneratedChoices([...regenChoices]);
        } catch (e) {
            console.log('error>>', e);
        }
    };

    const saveData = () => {
        analytics.sendClickEvent(
            'save_after_edit_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.SAVE_EDIT
        );
        updateChoices([...updatedData]);
        setTimeout(() => {
            handleSave();
        }, 1000);
    };

    const cancelEdit = () => {
        analytics.sendClickEvent(
            'cancel_edit_click',
            ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
            ANALYTICS.ACTIONS.CANCEL_EDIT
        );
        setUpdatedData([...data]);
    };

    const handleFeedbackSubmit = (
        type: string,
        audienceLabel: string,
        isLiked: boolean,
        comment = '',
        key?: number
    ) => {
        handleFeedback(type, audienceLabel, key, isLiked, comment);

        if (isLiked) {
            const event = key && key >= 0 ? `${type}_${key}_like_click` : `${type}_like_click`;
            analytics.sendClickEvent(
                event,
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.LIKE_CLICK
            );
        } else {
            const event =
                key && key >= 0 ? `${type}_${key}_dislike_click` : `${type}_dislike_click`;
            analytics.sendClickEvent(
                event,
                ANALYTICS.SCREEN_NAMES.RESULTS_VIEW,
                ANALYTICS.ACTIONS.DISLIKE_CLICK
            );
        }
    };

    const handleInstructionsChange = (e: { target: { value: string } }) => {
        const { value } = e?.target;
        setResultInstructions(value);
    };

    const hideInstructionBox = () => {
        setResultInstructions('');
        setShowInstructionBox(null);
    };

    const renderInstructions = (
        audience: IAudience,
        targetKey: number,
        key: number,
        type: string
    ) => {
        return (
            <div className={cx(styles.instructionBox, 'bg-white rounded-lg shadow', 'p-4')}>
                <div className={cx('relative')}>
                    <CustomInput
                        customCss={{ padding: 0, height: '100%' }}
                        inputType="textarea"
                        value={resultInstructions}
                        onChange={handleInstructionsChange}
                        label="Enter Instructions"
                        rows={6}
                    />
                    <BetaTag classname={cx('top-[-0.5rem] left-40')} />
                </div>

                <div className={cx('flex justify-between', 'mt-2')}>
                    <CustomButton
                        title=""
                        onClick={hideInstructionBox}
                        className={cx(ICON_CANCEL, styles.instructionCta, 'text-[10px]')}
                    />
                    <CustomButton
                        title=""
                        onClick={() => {
                            if (!resultInstructions) return;
                            handleRegenerate(audience, targetKey, key, type, resultInstructions);
                            setShowInstructionBox(null);
                            handleInstructionsChange({ target: { value: '' } });
                        }}
                        className={cx(ICON_COMMAND, styles.instructionCta, 'ml-2')}
                    />
                </div>
            </div>
        );
    };

    const clearCurrentTrends = () => setCurrentTrends('');

    const updateTrends = (data: string) => setCurrentTrends(data);

    const renderContent = (
        choice: ITargetChoice,
        targetKey: number,
        key: number,
        audience: IAudience,
        regeneratedChoices: IRegeneratedChoice
    ) => {
        const contentFeedback =
            feedback?.find(
                (feed) => feed.contentType === choice?.type && feed.audienceType === audience?.label
            ) || null;

        const allowRegenerate = (isEditable && !regeneratedChoices?.choices?.length) || !isEditable;
        const allowCurrentTrends = (isEditable && !currentTrends?.length) || !isEditable;

        const showAllCtas = updatedData.length === 1;

        return (
            <div key={key} className={styles.containerContentRow}>
                {/* <div className={styles.containerContentAccept}>
                    <Checkbox
                        className={styles.configureItem}
                        data={{ value: choice?.type, label: '' }}
                        key={key}
                        handleCheck={() => handleAccept(targetKey, key, choice?.type)}
                        isChecked={choice?.accepted}
                    />
                </div> */}
                <div className={cx('relative', 'flex-1')}>
                    <ListData
                        choice={choice}
                        targetKey={targetKey}
                        pos={key}
                        updateResult={updateResult}
                        cancelEdit={cancelEdit}
                        currentTrends={currentTrends}
                        clearCurrentTrends={clearCurrentTrends}
                        regeneratedChoices={regeneratedChoices}
                        updateTrends={updateTrends}
                        isEditable={isEditable}
                        setIsEditable={setIsEditable}
                        updateRegenChoices={updateRegenChoices}
                    />
                </div>

                <div
                    className={cx(
                        styles.containerContentAction,
                        showAllCtas && 'bg-gray-200',
                        'p-2',
                        'rounded-xl'
                    )}
                >
                    <div className={cx('flex flex-col')}>
                        <label htmlFor={`${choice.type}maxWords`}>
                            &asymp; Words : {maxWords[choice.type]}
                        </label>
                        <input
                            id={`${choice.type}maxWords`}
                            type={'range'}
                            value={maxWords[choice.type]}
                            step={10}
                            min={10}
                            max={2000}
                            onChange={(e) => handleRangeChange(e, choice?.type)}
                            className={styles.wordRange}
                        />
                    </div>

                    <div
                        className={cx(
                            'flex items-center flex-wrap justify-between',
                            showAllCtas && 'w-full'
                        )}
                    >
                        <CustomButton
                            gradient
                            title={LABELS.REGENERATE}
                            onClick={() => {
                                !allowRegenerate &&
                                    toast.info(
                                        'Please Accept one choice or close edit mode to regenerate again',
                                        {
                                            toastId: 1
                                        }
                                    );
                                allowRegenerate &&
                                    handleRegenerate(audience, targetKey, 0, choice.type);
                            }}
                            className={cx(
                                showAllCtas ? actionsCss : 'bg-[#104891] text-white hover:none'
                            )}
                        />

                        {showAllCtas && (
                            <CustomActions
                                allowCurrentTrends={allowCurrentTrends}
                                showAction={!regeneratedChoices?.choices?.[targetKey]?.length}
                                setCurrentTrends={setCurrentTrends}
                                content={content}
                                seoKeywords={seoKeywords}
                            />
                        )}
                    </div>

                    <CustomButton
                        className={cx(
                            'mt-4',
                            showAllCtas ? 'bg-gray-300 text-black' : 'bg-[#104891] text-white',
                            showAllCtas ? 'hover:bg-gray-400' : 'hover:none',
                            'px-3'
                        )}
                        gradient
                        title={LABELS.REGENERATE_WITH_INSTRUCTIONS}
                        onClick={() => {
                            setShowInstructionBox(key);
                        }}
                    />

                    {showInstructionBox === key &&
                        renderInstructions(audience, targetKey, key, choice.type)}

                    <Feedback
                        contentFeedback={contentFeedback}
                        handleFeedbackSubmit={(isLiked, comment) =>
                            handleFeedbackSubmit(choice.type, audience?.label, isLiked, comment)
                        }
                    />
                </div>
            </div>
        );
    };

    const renderBdrContent = (
        choice: ITargetChoice,
        targetKey: number,
        key: number,
        regeneratedChoice: IRegeneratedChoice,
        audience: IAudience
    ) => {
        return (
            <div key={key} className={styles.containerContentRow}>
                {/* <div className={styles.containerContentAccept}>
                    <Checkbox
                        className={styles.configureItem}
                        data={{ value: choice?.type, label: '' }}
                        key={key}
                        handleCheck={() => handleAccept(targetKey, key, choice?.type)}
                        isChecked={choice?.accepted}
                    />
                </div> */}
                {choice?.type === 'bdrCadence' ? (
                    <BdrCadenceResult
                        targetKey={targetKey}
                        choice={choice}
                        pos={key}
                        audience={audience}
                        updateResult={updateResult}
                        cancelEdit={cancelEdit}
                        saveData={saveData}
                        maxWords={maxWords}
                        handleRangeChange={handleRangeChange}
                        handleRegenerate={handleRegenerate}
                        regeneratedChoice={regeneratedChoice}
                        subType={subType}
                        handleFeedbackSubmit={handleFeedbackSubmit}
                        feedback={feedback}
                    />
                ) : (
                    <BdrMail
                        targetKey={targetKey}
                        choice={choice}
                        pos={key}
                        audience={audience}
                        updateResult={updateResult}
                        cancelEdit={cancelEdit}
                        saveData={saveData}
                        maxWords={maxWords}
                        handleRangeChange={handleRangeChange}
                        handleRegenerate={handleRegenerate}
                        regeneratedChoice={regeneratedChoice}
                        handleFeedbackSubmit={handleFeedbackSubmit}
                        feedback={feedback}
                    />
                )}
            </div>
        );
    };

    // const renderTags = () => {
    //     if (!tags?.length) return;

    //     const TAGS = tags instanceof Array ? tags : tags.split(',').map((t) => t.trim());
    //     return (
    //         <div className={styles.tags}>
    //             {TAGS.map((tag, key) => (
    //                 <div className={styles.tagsName} key={key}>
    //                     {tag}
    //                 </div>
    //             ))}
    //         </div>
    //     );
    // };

    return (
        <div className={styles.container}>
            <h1 className={styles.containerTitle}>{title}</h1>
            {/* <div className={styles.containerHeadings}>
                <div className={styles.containerHeadingsAccept}>{STRINGS.ACCEPT}</div>
                <div className={styles.containerHeadingsLeft}>{STRINGS.CONTENT}</div>
                <div className={styles.containerHeadingsRight}>{STRINGS.ACTION}</div>
            </div> */}
            <div className={styles.containerContent}>
                {updatedData.map((target, targetKey) => {
                    return (
                        <div key={targetKey} className={'mt-2'}>
                            <div className={cx('flex', 'py-1 text-xl text-slate-800')}>
                                <h1 className={cx('font-semibold', 'mr-2')}>Audience:</h1>
                                <span className={cx('font-bold drop-shadow-2xl')}>
                                    {target?.audience?.label || target?.audience?.name}
                                </span>
                            </div>
                            {target?.choices?.map((choice, idx) => {
                                let regenString = regeneratedChoices[targetKey]?.contentTypes[idx];

                                return [CONTENT_TYPE[2].value, 'bdrCadence']?.includes(choice?.type)
                                    ? renderBdrContent(
                                          choice,
                                          targetKey,
                                          idx,
                                          regenString,
                                          target.audience
                                      )
                                    : renderContent(
                                          choice,
                                          targetKey,
                                          idx,
                                          target?.audience,
                                          regenString
                                      );
                            })}
                        </div>
                    );
                })}

                {/* {renderTags()} */}
            </div>

            <div
                className={cx(
                    { 'justify-end': isComplete, 'justify-between': !isComplete },
                    styles.containerFooter
                )}
            >
                {!isComplete && <CustomButton title={STRINGS.BACK} onClick={handleBack} />}
                <CustomButton title={STRINGS.NEXT} onClick={handleNext} />
            </div>
        </div>
    );
};

export default React.memo(ResultTab);
