import React, { MouseEvent, useEffect, useState } from 'react';
import cx from 'classnames';

//defs
import type { IAudience, IChoices, ITargetChoice } from 'src/defs';
import type { IRegeneratedChoice } from '../ResultTab';

//constants
import { CONTENT_TYPE } from 'src/shared/constants';

interface IProps {
    data: IChoices[];
    groupBy: string;
    regeneratedChoices: { contentTypes: IRegeneratedChoice[] }[];
    renderBdrContent: (
        choice: ITargetChoice,
        targetKey: number,
        key: number,
        regeneratedChoice: IRegeneratedChoice,
        audience: IAudience
    ) => JSX.Element;
    renderContent: (
        choice: ITargetChoice,
        targetKey: number,
        key: number,
        audience: IAudience,
        regeneratedChoices: IRegeneratedChoice
    ) => JSX.Element;
}

const Tabs = ({ data, groupBy, regeneratedChoices, renderBdrContent, renderContent }: IProps) => {
    const [activeTab, setActiveTab] = useState(0);
    const [activeTabData, setActiveTabData] = useState(data[0]);

    useEffect(() => {
        setActiveTabData(data[activeTab]);
    }, [activeTab, data]);

    const handleTabSelect = (e: MouseEvent<HTMLDivElement>) => {
        setActiveTab(Number(e.currentTarget.id));
    };

    const renderTitles = () => {
        const TITLES = data.map(
            (target: IChoices, key) =>
                target?.audience?.[groupBy as keyof IAudience] ||
                target?.audience?.name ||
                `${target?.audience?.firstName} ${target?.audience?.lastName}`
        );

        return (
            <div
                className={cx(
                    'flex items-center gap-3 pr-12',
                    'w-[calc(100vw_-_112px)] overflow-hidden overflow-x-scroll no-scrollbar'
                )}
            >
                {TITLES.map((title, key) => (
                    <div
                        key={key}
                        className={cx(
                            'font-apple cursor-pointer text-nowrap',
                            'p-2 rounded-t min-w-[200px] max-w-[200px] overflow-hidden',
                            activeTab === key ? `bg-slate-200 shadow-inner` : `bg-slate-400 shadow`,
                            `hover:bg-slate-200 shadow-inner`
                        )}
                        id={`${key}`}
                        onClick={handleTabSelect}
                    >
                        {title}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className={cx('px-4 mb-4 font-apple !font-apple')}>
            {renderTitles()}
            <div className={cx('bg-slate-200 px-2', 'shadow')}>
                {activeTabData?.choices?.map((choice, idx) => {
                    let regenString = regeneratedChoices[activeTab]?.contentTypes[idx];

                    return [CONTENT_TYPE[2].value, 'bdrCadence', 'advanced-bdrCadence']?.includes(
                        choice?.type
                    )
                        ? renderBdrContent(
                              choice,
                              activeTab,
                              idx,
                              regenString,
                              activeTabData.audience
                          )
                        : renderContent(
                              choice,
                              activeTab,
                              idx,
                              activeTabData?.audience,
                              regenString
                          );
                })}
            </div>
        </div>
    );
};

export default React.memo(Tabs);
